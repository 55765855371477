.modal-content {
    background-color: #0a1b26;
    color: #fff;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: -webkit-gradient(linear, left bottom, left top, from(#2e4d62), to(#2e4d62)), #fff;
        background: linear-gradient( 0deg, #2e4d62, #2e4d62), #fff;
        opacity: 0.09;
        -webkit-backdrop-filter: blur(18px);
        backdrop-filter: blur(18px);
    }
    .modal-header {
        padding: 20px 30px;
        border-bottom: 0;
        position: relative;
        .modal-title {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 0;
            width: 100%;
            text-align: center;
        }
        .btn-close {
            position: absolute;
            outline: none !important;
            box-shadow: none !important;
            top: 24px;
            right: 24px;
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
        }
    }
    .modal-body {
        padding: 10px 40px 40px;
    }
}

.modal-backdrop {
    background-color: #1f4543;
    &.show {
        opacity: .9;
    }
}

@media (min-width: 576px) {
    .modal-sm {
        max-width: 360px;
    }
}
.decor {
    max-width: 490px;
    width: 100%;
    position: absolute;
    pointer-events: none;
    &.main-decor {
        top: 170px;
        left: -75px;
        -webkit-animation-name: animationDecor;
        animation-name: animationDecor;
        -webkit-animation-duration: 2000ms;
        animation-duration: 2000ms;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }
    &.bottom-decor {
        bottom: 60px;
        right: 20px;
        max-width: 300px;
        -webkit-animation-name: decorShine;
        animation-name: decorShine;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    img {
        max-width: 100%;
    }
}

@-webkit-keyframes decorShine {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes decorShine {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes animationDecor {
    0% {
        -webkit-transform: translate(100%, 100%);
        transform: translate(100%, 100%);
    }
    40% {
        -webkit-transform: translate(75%, 75%) rotate(90deg);
        transform: translate(75%, 75%) rotate(90deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(360deg);
        transform: translate(0, 0) rotate(360deg);
    }
}

@keyframes animationDecor {
    0% {
        -webkit-transform: translate(100%, 100%);
        transform: translate(100%, 100%);
    }
    40% {
        -webkit-transform: translate(75%, 75%) rotate(90deg);
        transform: translate(75%, 75%) rotate(90deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(360deg);
        transform: translate(0, 0) rotate(360deg);
    }
}
.vv-loading {
    background-color: #0a1b26;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    user-select: none;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        transition: all ease-in-out .3s;
        transform-origin: center center;
        animation: loadingEffect 2s infinite;
    }
}

@keyframes loadingEffect {
    0% {
        transform: scale(1);
        opacity: .3;
    }
    50% {
        transform: scale(1.1);
        opacity: .7;
    }
    100% {
        transform: scale(1);
        opacity: .3;
    }
}
.spinner {
    animation: 2s spinnerRorate linear infinite;
}

@keyframes spinnerRorate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
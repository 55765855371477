.navbar {
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.5s;
    z-index: 999;
    top: 0;
    background: radial-gradient( 45.09% 99.51% at 58.01% -0.08%, #020a0f 0%, #0a1b26 93.15%) !important;
    .navbar-nav {
        .nav-link {
            font-size: 15px;
            line-height: 22px;
            background: -webkit-gradient( linear, left top, right top, from(#0347e0), color-stop(50%, #00feb5), color-stop(50%, #fff));
            background: linear-gradient(to right, #0347e0, #00feb5 50%, #fff 50%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% 100%;
            background-position: 100%;
            -webkit-transition: background-position 275ms ease;
            transition: background-position 275ms ease;
            text-decoration: none;
            position: relative;
            padding-left: 30px !important;
            padding-right: 30px !important;
            &.active,
            &:hover {
                background-position: 0 100%;
            }
        }
    }
    .navbar-toggler {
        &:focus {
            box-shadow: none !important;
        }
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
        }
    }
}
.vv-notice {
    padding: 16px 20px 16px 70px;
    background: linear-gradient( 122.83deg, #eea21d 11.65%, #7cfcb6 42.38%, #2a48e0 66.79%);
    margin-bottom: 24px;
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    position: relative;
    z-index: 3;
    font-size: 18px;
    i {
        line-height: 1;
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
    }
    .vv-notice-decor {
        position: absolute;
        -webkit-animation-name: noticeAnimation;
        animation-name: noticeAnimation;
        -webkit-animation-duration: 3500ms;
        animation-duration: 3500ms;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
        width: 13px;
        height: 158px;
        top: -44px;
        left: 0;
        &:nth-child(2) {
            -webkit-animation-delay: 0.7s;
            animation-delay: 0.7s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: 1.4s;
            animation-delay: 1.4s;
        }
        &:nth-child(4) {
            -webkit-animation-delay: 2.1s;
            animation-delay: 2.1s;
        }
        &:nth-child(5) {
            -webkit-animation-delay: 2.8s;
            animation-delay: 2.8s;
        }
    }
}

@keyframes noticeAnimation {
    0% {
        left: -30px;
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
    95% {
        left: 53px;
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 60px;
    }
}
.modal-wrap {
    width: 100%;
    overflow-y: auto;
    .modal-section,
    .modal-section-bottom {
        display: grid;
        grid-auto-rows: auto;
        padding: 24px;
    }
    .modal-section {
        .modal-content-header {
            align-items: center;
            display: flex;
            border-bottom: 1px #0a1b2621 solid;
            padding-bottom: 10px;
            h5 {
                flex: 1;
                font-weight: 500;
                text-align: center;
                font-size: 24px;
            }
            .btn {
                &.cs-close {
                    font-size: 24px;
                    line-height: 1;
                    opacity: .7;
                    transition: all ease-in-out .3s;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        .confirm-icon {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            align-items: center;
            padding: 40px 0;
        }
    }
}
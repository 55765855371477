.p-progress-waiting {
    width: 300px;
    margin: 30px auto 15px auto;
    height: 10px;
    background-color: #dedede;
    position: relative;
    .p-progress-percent {
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        max-width: 100%;
        background-color: #00feb5;
        -webkit-box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
        box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
    }
}
.vv-checkpoint-card {
    cursor: pointer;
    padding: 20px;
    position: relative;
    padding-right: 40px;
    background-color: #021b26;
    box-shadow: 41px 50px 60px rgb(10 1 30 / 50%);
    height: calc(100% - 30px);
    text-align: left;
    margin-bottom: 30px;
    &::after {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        content: "";
        right: 15px;
        top: 15px;
        position: absolute;
        background-color: #7cfcb61c;
    }
    &::before {
        width: 14px;
        height: 14px;
        border-radius: 3px;
        content: "";
        background-color: transparent;
        right: 18px;
        top: 18px;
        position: absolute;
        color: #fff;
        font-size: 13px;
        text-align: center;
        line-height: 1;
    }
    &.selected {
        background: linear-gradient( 127.41deg, rgba(0, 254, 181, 0.31) 16.65%, rgba(0, 254, 181, 0.124) 41.34%, rgba(0, 254, 181, 0) 67.45%);
        &::before {
            content: "✓";
            font-weight: bold;
        }
        &::after {
            background-color: #7cfcb669;
        }
        .vv-checkpoint-card-title {
            b {
                color: #00feb5;
            }
        }
        .vv-checkpoint-card-description {
            color: #fff;
        }
    }
    .vv-checkpoint-card-title {
        color: #212121;
        font-size: 14px;
        margin-bottom: 10px;
        b {
            font-family: "Poppins", sans-serif;
            font-weight: 500 !important;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .vv-checkpoint-card-description {
        font-size: 14px;
        min-height: 5rem;
        color: #ffffff70;
    }
}

@media(max-width:1200px) {
    .vv-checkpoint-card {
        padding-right: 15px;
        .vv-checkpoint-card-title {
            b {
                font-size: 11.5px;
            }
        }
        .vv-checkpoint-card-description {
            font-size: 12px;
            min-height: 6rem;
        }
    }
}
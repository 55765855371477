.vv-stepper__progress {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    margin-bottom: 40px;
    .vv-stepper__progress-btn {
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        transition-delay: 0s;
        position: relative;
        padding-top: 28px;
        color: rgba(255, 255, 255, 0.7);
        text-indent: -9999px;
        border: none;
        background-color: transparent;
        outline: none !important;
        cursor: pointer;
        &:before {
            position: absolute;
            top: -10px;
            left: 50%;
            display: block;
            width: 30px;
            height: 30px;
            content: '';
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
            transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
            transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
            border-radius: 50%;
            box-sizing: border-box;
            z-index: -1;
            background-image: url(/images/planet1.png);
            background-size: cover;
            -webkit-animation-name: planetRotate;
            animation-name: planetRotate;
            -webkit-animation-duration: 6s;
            animation-duration: 6s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            filter: grayscale(0.6);
        }
        &:after {
            position: absolute;
            top: 5px;
            left: calc(-50% - 13px / 2);
            transition-property: all;
            transition-duration: 0.15s;
            transition-timing-function: linear;
            transition-delay: 0s;
            display: block;
            width: 100%;
            height: 1px;
            content: '';
            background-color: currentColor;
            z-index: -2;
        }
        &:first-child {
            &:after {
                display: none;
            }
        }
        &.completed {
            color: #00feb5;
            &:before {
                -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
                background-color: currentColor;
                animation-name: planetRotate;
                filter: grayscale(0);
            }
            &:after {
                left: calc(-50% - 13px / 2);
                width: calc(100% - 36px + 48px);
            }
        }
        &.active {
            color: #00feb5;
            background-image: url(/images/line.png);
            background-size: 97% auto;
            background-position: top -3px center;
            background-repeat: no-repeat;
            &:before {
                -webkit-transform: translateX(-50%) scale(1.2);
                transform: translateX(-50%) scale(1.2);
                background-color: currentColor;
                animation-name: planetRotate2;
                filter: grayscale(0);
            }
            &:after {
                left: calc(-50% - 13px / 2);
                width: calc(100% - 36px);
            }
            &+button {
                &:after {
                    left: calc(-50% - 13px / 2 + 48px) !important;
                    width: calc(100% - 48px) !important;
                }
            }
        }
    }
}

.vv-step-title {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    color: #00feb5;
    padding-top: 20px;
}

.vv-step-desc {
    text-align: center;
    margin-bottom: 40px;
    color: rgba(255, 255, 255, 0.8);
    i {
        font-size: 15px;
        display: block;
        color: rgba(255, 255, 255, 0.6);
    }
}

@keyframes planetRotate {
    0% {
        -webkit-transform: translateX(-50%) rotate(0);
        transform: translateX(-50%) rotate(0);
    }
    100% {
        -webkit-transform: translateX(-50%) rotate(360deg);
        transform: translateX(-50%) rotate(360deg);
    }
}

@keyframes planetRotate2 {
    0% {
        -webkit-transform: translateX(-50%) rotate(0) scale(1.2);
        transform: translateX(-50%) rotate(0) scale(1.2);
    }
    100% {
        -webkit-transform: translateX(-50%) rotate(360deg) scale(1.2);
        transform: translateX(-50%) rotate(360deg) scale(1.2);
    }
}

@media(min-width:576px) {
    .vv-stepper__progress {
        .vv-stepper__progress-btn {
            text-indent: 0;
            &.completed,
            &.active {
                background-size: auto 21px;
            }
        }
    }
}
.vv-input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    max-width: 100%;
    background-color: #485760;
    .form-control {
        border: 0;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        font-size: 24px;
        font-weight: 500;
        color: #fff;
        &::placeholder {
            color: rgba(255, 255, 255, 0.7);
        }
    }
    .btn {
        &.btn-primary {
            width: auto;
        }
    }
}
.pp-footer {
    margin-top: 50px;
    padding: 10px 0;
    font-size: 12px;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    .footer-block {
        position: relative;
        background: linear-gradient( 145.37deg, rgba(0, 254, 181, 0.2) 8.43%, rgba(0, 254, 181, 0.08) 36.87%, rgba(0, 254, 181, 0) 66.94%);
        -webkit-backdrop-filter: blur(18px);
        backdrop-filter: blur(18px);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 45px 40px;
        margin-bottom: 30px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: -webkit-gradient( linear, left bottom, left top, from(#2e4d62), to(#2e4d62)), #fff;
            background: linear-gradient(0deg, #2e4d62, #2e4d62), #fff;
            opacity: 0.09;
            -webkit-backdrop-filter: blur(18px);
            backdrop-filter: blur(18px);
        }
        .footer-block__info {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            p {
                padding-top: 5px;
                padding-right: 5px;
            }
            .footer-block__links {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: flex-end;
            }
            .footer-block__link {
                margin-left: 35px;
                img {
                    -webkit-transition: all 0.15s ease-in;
                    transition: all 0.15s ease-in;
                }
                &:hover img {
                    -webkit-transform: scale(0.9);
                    transform: scale(0.9);
                }
            }
        }
        .logo {
            max-width: 136px;
            width: 100%;
        }
    }
    .pp-footer-link {
        a {
            color: rgba(255, 255, 255, 0.8);
            padding: 0 6px;
            text-decoration: none;
        }
    }
}

@media (max-width: 767px) {
    .pp-footer-content {
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        >div {
            white-space: nowrap;
            margin-bottom: 3px;
            width: 100%;
            text-align: center;
        }
        .pp-footer-link {
            text-align: center !important;
        }
    }
    .footer-block {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 30px !important;
        .footer-block__info {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            .footer-block__links {
                margin-top: 0px;
                .footer-block__link {
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .logo {
            margin-bottom: 20px;
        }
    }
}
.vv-card {
    font-size: 16px;
    padding: 10px 21px !important;
    color: #fff;
    font-weight: 600;
    background-image: linear-gradient( 127.41deg, rgba(0, 254, 181, 0.31) 16.65%, rgba(0, 254, 181, 0.124) 41.34%, rgba(0, 254, 181, 0) 67.45%);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    background-size: 200% 100%;
    box-shadow: 41px 50px 60px rgb(10 1 30 / 50%);
    position: relative;
    margin-bottom: 24px;
    height: calc(100% - 24px);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(0deg, #2e4d62, #2e4d62), #fff;
        opacity: 0.09;
        -webkit-backdrop-filter: blur(18px);
        backdrop-filter: blur(18px);
        z-index: -1;
    }
    &.style-1 {
        background: linear-gradient(310.92deg, rgba(0, 254, 181, 0.35) 0%, rgb(9 17 35 / 55%) 56.25%);
        &::before {
            background: linear-gradient( 0deg, #2e4d62, #2e4d62), #fff;
        }
        .vv-card-body {
            .vv-card-value {
                color: #fff;
            }
        }
    }
    &.style-2 {
        background: linear-gradient( 299.4deg, rgba(3, 71, 224, 0.57) 1.97%, rgba(3, 71, 224, 0.302) 25.82%, rgba(3, 71, 224, 0) 53.87%);
        &::before {
            background: #2e4d62;
        }
        .vv-card-body {
            .vv-card-value {
                color: #fff;
            }
        }
    }
    &.style-3 {
        background: linear-gradient( 122.83deg, rgba(248, 50, 85, 0.45) 11.65%, rgba(248, 50, 85, 0) 42.38%, rgba(248, 50, 85, 0) 66.79%);
        &::before {
            background: #2e4d62;
        }
        .vv-card-body {
            .vv-card-value {
                color: #fff;
            }
        }
    }
    .vv-card-title {
        font-size: 15px;
        color: rgba(255, 255, 255, 0.8);
        border-bottom: 1px #6cbf9b26 solid;
        padding-bottom: 8px;
    }
    .vv-card-body {
        padding-top: 10px;
        min-height: 60px;
        .vv-card-value {
            font-weight: 600;
            font-size: 30px;
            line-height: 100%;
            color: #00feb5;
        }
    }
    .vv-card-actions {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        .btn:not(:last-child) {
            margin-right: 10px;
        }
    }
}
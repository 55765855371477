.p-sidebar {
    position: fixed;
    right: 0;
    padding: 10px 20px 20px 20px;
    width: 290px;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 81px);
}

.p-sidebar-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-sidebar-close {
    display: none;
}

.p-sidebar-backdrop {
    z-index: -1;
    opacity: 0;
}

.p-sidebar-close .btn {
    line-height: 1;
    margin-top: -10px;
    font-size: 30px;
}

@media(max-width:992px) {
    .p-sidebar-close {
        display: block;
    }
    .p-sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        transition: all ease-in-out .3s;
    }
    .p-sidebar.active+.p-sidebar-backdrop {
        z-index: 999;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        content: '';
        position: fixed;
        opacity: 1;
        transition: opacity ease-in-out .3s;
    }
    .p-sidebar.active {
        z-index: 9999;
        right: 0;
    }
    .p-sidebar {
        order: 1;
        position: relative;
        display: block;
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        left: 0;
    }
    .p-sidebar-close {
        display: none;
    }
}

@media(max-width:768px) {
    .p-sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        transition: all ease-in-out .3s;
    }
    .p-sidebar.active+.p-sidebar-backdrop {
        z-index: 999;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        content: '';
        position: fixed;
        opacity: 1;
        transition: opacity ease-in-out .3s;
    }
    .p-sidebar.active {
        z-index: 9999;
        right: 0;
    }
}
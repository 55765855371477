.btn {
    outline: none !important;
    white-space: nowrap;
    &.btn-primary {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #030407;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: transparent;
        background: #00feb5;
        width: 100%;
        height: 52px;
        padding: 10px 20px;
        overflow: hidden;
        position: relative;
        -webkit-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
        border: 0;
        border-radius: 0 !important;
        &.border {
            background: transparent;
            color: #00feb5;
            border: 1px solid #00feb5;
            line-height: 20px;
            &:hover {
                -webkit-animation-name: buttonHoverBorder;
                animation-name: buttonHoverBorder;
            }
        }
        span {
            position: absolute;
            display: block;
            &:nth-child(1) {
                top: 0;
                left: -100%;
                width: 100%;
                height: 2px;
                background: -webkit-gradient(linear, left top, right top, from(transparent), to(#00feb5));
                background: linear-gradient(90deg, transparent, #00feb5);
            }
            &:nth-child(2) {
                top: -100%;
                right: 0;
                width: 2px;
                height: 100%;
                background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#00feb5));
                background: linear-gradient(180deg, transparent, #00feb5);
            }
            &:nth-child(3) {
                bottom: 0;
                right: -100%;
                width: 100%;
                height: 2px;
                background: -webkit-gradient(linear, right top, left top, from(transparent), to(#00feb5));
                background: linear-gradient(270deg, transparent, #00feb5);
            }
            &:nth-child(4) {
                bottom: -100%;
                left: 0;
                width: 2px;
                height: 100%;
                background: -webkit-gradient(linear, left bottom, left top, from(transparent), to(#00feb5));
                background: linear-gradient(360deg, transparent, #00feb5);
            }
        }
        &.focus,
        &:focus,
        &.active,
        &:active,
        &.disabled,
        &:disabled {
            outline: none !important;
            box-shadow: none !important;
            color: #fff;
            background-color: #0caa7d;
            border-color: #0caa7d;
        }
        &:hover {
            -webkit-animation-name: buttonHover;
            animation-name: buttonHover;
            -webkit-animation-duration: 2000ms;
            animation-duration: 2000ms;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            span {
                &:nth-child(1) {
                    left: 100%;
                    -webkit-transition: 600ms;
                    transition: 600ms;
                }
                &:nth-child(2) {
                    top: 100%;
                    -webkit-transition: 1s;
                    transition: 1s;
                    -webkit-transition-delay: 100ms;
                    transition-delay: 100ms;
                }
                &:nth-child(3) {
                    right: 100%;
                    -webkit-transition: 1s;
                    transition: 1s;
                    -webkit-transition-delay: 250ms;
                    transition-delay: 250ms;
                }
                &:nth-child(4) {
                    bottom: 100%;
                    -webkit-transition: 1s;
                    transition: 1s;
                    -webkit-transition-delay: 450ms;
                    transition-delay: 450ms;
                }
            }
        }
    }
    &.btn-sm {
        height: 38px;
        padding: 6px 16px;
        font-size: 16px;
        line-height: 1.3;
    }
}

@-webkit-keyframes buttonHover {
    0% {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
    }
    50% {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    90% {
        background: #00feb5;
        color: #000;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
        box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
    }
}

@keyframes buttonHover {
    0% {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
    }
    50% {
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    90% {
        background: #00feb5;
        color: #000;
    }
    100% {
        -webkit-box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
        box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
    }
}

@-webkit-keyframes buttonHoverBorder {
    0% {
        border-color: transparent;
    }
    60% {
        border-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    100% {
        border-color: #00feb5;
        -webkit-box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
        box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
    }
}

@keyframes buttonHoverBorder {
    0% {
        border-color: transparent;
    }
    60% {
        border-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    100% {
        border-color: #00feb5;
        -webkit-box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
        box-shadow: 0 0 10px rgba(0, 254, 181, 0.3), 0 0 40px rgba(0, 254, 181, 0.3), 0 0 80px rgba(0, 254, 181, 0.3);
    }
}
.vv-layout {
    padding: 30px 0 60px 0;
    &.vv-pending {
        position: relative;
        &::before {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            content: '';
            position: fixed;
            backdrop-filter: blur(64px);
            background-color: #0a1b26;
            z-index: 2;
            opacity: .8;
            overflow: hidden;
        }
    }
    .vv-layout-inner {
        padding-top: 50px;
    }
    .vv-page-title {
        font-weight: 500;
        font-size: 50px;
        line-height: 119.5%;
        color: #fff;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 0;
        position: relative;
        span {
            color: #00feb5;
        }
    }
}
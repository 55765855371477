body {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    background-color: #0a1b26;
    padding-top: 92px;
    position: relative;
}

a {
    color: #00feb5;
    transition: color ease-in-out .3s;
}

a:hover {
    color: #3FB5FD;
}

h1,
h2,
h3,
h4 {
    font-family: 'Poppins', sans-serif;
}

.text-warning {
    color: #f0ac1a !important;
}

.block-ui-container {
    height: 100vh !important;
    position: fixed;
}

.block-ui-overlay {
    position: fixed !important;
}

@import "components/loading";
@import "components/layout";
@import "components/scrollbar";
@import "components/navbar";
@import "components/modal";
@import "components/transaction-modal";
@import "components/input";
@import "components/button";
@import "components/decor";
@import "components/spinner";
@import "components/progress";
@import "components/table";
@import "components/sidebar";
@import "components/card";
@import "components/checkpoint-card";
@import "components/stepper";
@import "components/notice";
@import "components/form";
@import "components/footer";